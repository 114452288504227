.house-show {
    background-color: #f4f3ee;
    padding-top: 50px;
    width: 100%;

    img {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: block;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    section {
        padding: 0 0 100px 0;
        margin: 0 auto;
        max-width: 800px;
    }
}