.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 80px 20px;

    img {
        max-width: 600px;
        margin: 20px auto;
        width: 100%;
        height: auto;
    }

    p {
        color: white;
        font-size: 12px;
        max-width: 390px;
        width: 100%;
        text-align: justify;
        line-height: 18px;

        a {
            color: white;
        }
    }
}