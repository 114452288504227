.form-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    section {
        cursor: initial;
        flex: 0 0 auto;
        background-color: black;
        padding: 10px 40px;
        margin: 0 auto;
        font-size: 16px;
        color: white;
        max-width: 500px;
        width: 100%;
        font-weight: 400;
        text-align: center;

        svg {
            margin: 25px 0 9px;
            width: 100px;
        }

        > span {
            display: block;
            margin: 20px 0;
        }

        form {
            h6 {
                margin: 0 0 9px;
                font-size: 48px;
                font-weight: 300;
                font-family: ambroise-std;
            }

            span {
                display: block;
                max-width: 400px;
                margin: 0 auto 15px;
                line-height: 20px;
            }

            textarea {
                border: none;
                border-top: 1px solid rgba(255,255,255,.5);
                border-bottom: 1px solid rgba(255,255,255,.5);
                outline: none;
                background: none;
                width: 100%;
                min-height: 200px;
                color: white;
                padding: 8px 0;
                margin-bottom: 5px;
            }

            input[type="text"] {
                background: none;
                width: 100%;
                border: none;
                outline: none;
                color: white;
                padding: 8px 0;
            }

            input[type="submit"] {
                font-family: owners-xwide;
                font-size: 14px;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: 2px;
                border: 1px solid rgba(255,255,255,.5);
                border-radius: 1px;
                color: white;
                background: none;
                outline: none;
                cursor: pointer;
                transition: border-color .15s ease, opacity .15s ease;
                display: block;
                padding: 5px 10px 3px;
                margin: 0 auto;

                &:not(:disabled):hover {
                    border-color: white;
                }

                &:disabled {
                    opacity: .5;
                }
            }
        }
    }
}