.menu-background {
    background-color: rgba(0,0,0,.5);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.menu-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    transition: left .15s ease;

    &.show {
        left: 0;
    }

    nav {
        background-color: black;
        padding: 20px;
        width: 100%;
        max-width: 600px;
        height: 100%;
        color: white;
        position: relative;

        svg {
            cursor: pointer;
            width: 34px;
            padding: 10px;
            float: right;

            path {
                transition: fill .15s ease;
            }

            &:hover {
                path {
                    fill: #7cb9e8;
                }
            }
        }

        > ul {
            list-style-type: none;
            margin: 0;
            padding: 42px 0 0 0;

            li {
                cursor: pointer;
                font-size: 36px;
                font-weight: 500;
                letter-spacing: -2px;
                color: white;
                -webkit-user-select: none;
                user-select: none;

                &:hover {
                    color: #7cb9e8;
                }

                a {
                    -webkit-user-select: none;
                    user-select: none;
                    text-decoration: none;
                    color: white;
                    transition: color .15s ease;

                    &:hover {
                        color: #7cb9e8;
                    }
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 5px 0;

                    li {
                        font-size: 24px;
                        border-top: 1px solid rgba(255, 255, 255, 0.38);
                        padding: 5px 0;
                        -webkit-user-select: none;
                        user-select: none;
                    }
                }
            }
        }

        div {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 20px 10px;
            text-align: center;

            a {
                color: white;
                font-size: 12px;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }
    }
}
