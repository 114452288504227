.podcasts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px;
    color: white;

    h2 {
        font-size: 24px;
        text-transform: uppercase;
        margin: 0 0 10px;
        padding: 0;

        &:nth-of-type(2) {
            margin-top: 40px;
        }
    }

    div {
        margin: 0 0 20px;

        a {
            color: white;
            font-size: 14px;
            margin: 0 5px;
        }
    }

}