* {
    font-family: owners-text;
}

body {
    font-size: 0;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 80px;
    background-color: rgba(0,0,0,.3);
    transition: height .3s ease, background-color .3s ease;
    width: 100%;

    @media(max-width: 600px) {
        height: 70px;
    }

    &.minimized {
        height: 50px;
        background-color: rgba(0,0,0,.9);

        > div h1 {
            text-shadow: none;

            svg {
                filter: none;
            }
        }

        button {
            text-shadow: none;

            svg {
                filter: none;
            }
        }
    }

    > div {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        > div {
            display: inline-block;

            flex: 0 1 auto;
            width: calc(100%/3);

            &.left {
                text-align: left;

                @media(max-width: 600px) {
                    width: initial;
                }
            }

            &.center {
                text-align: center;

                @media(max-width: 600px) {
                    flex-grow: 1;
                    text-align: left;
                }
            }

            &.right {
                text-align: right;
                padding-right: 20px;
            }
        }

        .menu-icon {
            width: 16px;
            color: white;
            cursor: pointer;
            padding: 20px;
            box-sizing: initial;

            path {
                transition: fill .15s ease;
            }

            &:hover {
                path {
                    fill: #ffc0cb;
                }
            }
        }

        h1 {
            cursor: pointer;
            padding: 0;
            margin: 0;
            transition: color .15s ease;

            a {
                color: white;
                font-family: owners-xwide;
                font-weight: 700;
                font-size: 22px;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 1px;

                @media(max-width: 950px) {
                    font-size: 18px;
                }
            }

            &:hover {
                a {
                    color: #ffc0cb;
                }
            }
        }

        button {
            display: inline-block;
            font-size: 14px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 2px;
            border: 1px solid rgba(255,255,255,.5);
            border-radius: 1px;
            color: white;
            background: none;
            outline: none;
            cursor: pointer;
            transition: border-color .15s ease;
            padding: 5px 10px 3px;

            @media(max-width: 700px) {
                font-size: 12px;
            }

            &:hover {
                border-color: white;
            }

            span {
                font-family: owners-xwide;
                vertical-align: middle;
            }

            svg {
                width: 13px;
                margin-left: 5px;
                vertical-align: middle;
                position: relative;
                bottom: 2px;
            }
        }
    }
}
