.hero {
    width: 100%;

    div {
        position: relative;
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }

        &:not(:first-of-type):before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to top, transparent 50%, black 100%);
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to bottom, transparent 50%, black 100%);
        }
    }

    section {
        text-align: center;
        padding: 20px 0;

        h1 {
            display: inline-block;
            vertical-align: middle;
            font-family: ambroise-std;
            color: white;
            text-transform: uppercase;
            font-size: 72px;
            line-height: 72px;
            font-weight: 300;
            padding: 0;
            margin: 0 20px 0;
            z-index: 1;

            @media(max-width: 1200px) {
                width: 100%;
                margin: 0 0 20px;
            }
        }

        p {
            font-weight: 700;
            display: inline-block;
            vertical-align: middle;
            color: white;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 24px;
            padding: 0;
            margin: 10px 20px;
            letter-spacing: 2px;
            text-align: left;
        }
    }
}