.mews {
    color: white;

    section {
        padding: 0 2.5%;
        width: 100%;
        overflow: auto;
        margin: 0 auto 40px auto;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media(max-width: 1000px) {
            margin-bottom: 20px;
        }

        @media(max-width: 600px) {
            margin-bottom: 2.5%;
        }

        h2 {
            font-size: 56px;
            font-weight: 400;
            text-align: center;
            padding: 0;
            margin: 0 0 40px;
        }

        h3 {
            font-size: 24px;
            text-transform: uppercase;
            transform: scaleY(.90);
            margin: 0 0 16px;
            padding: 0;

            @media(max-width: 550px) {
                font-size: 16px;
            }
        }

        h4 {
            font-size: 24px;
            font-style: italic;
            font-family: ambroise-std;
            font-weight: 400;
            text-align: center;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            margin: 0 0 16px;
            line-height: 22px;
        }

        img {
            height: auto;
            width: 100%;
            display: block;
        }
    }

    .title {
        width: 100%;
        padding: 0;
        position: relative;

        video {
            width: 100%;
        }

        div {
            position: absolute;
            left: 0;
            top: 20%;
            z-index: 1;
            width: 100%;
        }

        h1 {
            color: white;
            font-weight: 300;
            font-size: 80px;
            font-family: ambroise-std;
            text-align: center;
            width: 100%;
            padding: 0;
            margin: 40px auto 80px;
            text-transform: uppercase;
            max-width: 800px;
            display: block;
            margin-bottom: 0;
        }

        @media(max-width: 1250px) {
            div {
                top: 10%;
            }

            h1 {
                font-size: 70px;
            }
        }

        @media(max-width: 1000px) {
            h1 {
                font-size: 40px;
                max-width: 400px;
            }
        }

        @media(max-width: 550px) {
            div {
                position: initial;
            }

            h1 {
                color: black;
                font-size: 36px;
            }
        }

        @media(max-width: 450px) {
            h1 {
                font-size: 32px;
            }
        }
    }

    .photo-right {
        max-width: calc(900px + 5%);
        padding: 2.5% 0;
        font-size: 0;

        div {
            display: inline-block;
            vertical-align: middle;
            max-width: calc(100% - 600px);
            margin-right: 100px;
        }

        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 500px;
        }

        @media(max-width: 1000px) {
            padding-bottom: 0;

            div {
                display: block;
                margin: 0 auto 40px;
                max-width: 350px;
            }

            img {
                display: block;
                margin: 0 auto;
            }
        }

        @media(max-width: 600px) {
            padding: 2.5% 0 0;
        }
    }


    .carousel {
        background-color: #eadfc4;
        padding: 2.5%;
        overflow-x: scroll;
        white-space: nowrap;
        font-size: 0;
        position: relative;

        &:not(.spaced) {
            margin-bottom: 0;
        }

        &.small {
            background-color: black;
            padding: 1% 2.5%;

            img {
                width: 30%;
                margin-right: .95%;
                min-width: 250px;
            }
        }

        img {
            display: inline-block;
            width: 48.8125%;
            margin-right: 2.375%;
            min-width: 400px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        span {
            font-size: 10px;
            display: block;
            position: absolute;
            top: calc(50% - 10px);
            right: 10px;
            text-transform: uppercase;
            color: white;
            background-color: rgba(0,0,0,.5);
            padding: 5px 10px;
            transition: opacity .15s ease;

            &.hidden {
                opacity: 0;
            }
        }
    }

    .overlay {
        padding: 0%;
        width: 100%;
        position: relative;

        &.left {
            margin-bottom: 0;

            div {
                left: 2.5vw;
                top: 2.5vw;
                max-width: 500px;
                padding: 18px 20px 14px;

                @media(max-width: 720px) {
                    max-width: none;
                    right: 0;
                    bottom: 0;
                    box-sizing: border-box;
                }
            }
        }

        &:not(.left) {
            div {
                right: 2.5vw;
                bottom: 2.5vw;
                max-width: 600px;
                padding: 18px 40px 12px;

                @media(max-width: 720px) {
                    max-width: none;
                    right: 0;
                    bottom: 0;
                    box-sizing: border-box;
                }
            }
        }

        div {
            position: absolute;
            z-index: 1;
            background-color: rgba(0,0,0,.7);
        }

        p {
            color: white;
            max-width: 600px;
            margin: 0 auto;
            display: block;
            position: relative;

            @media(max-width: 750px) {
                font-size: 14px;
                line-height: 20px;
            }

            &.quote:before {
                content: '\201C';
                font-size: 48px;
                position: absolute;
                right: calc(100% + 2px);
                top: 2px;
            }
        }
    }

    .photo-left {
        text-align: center;

        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 500px;
            margin-right: 40px;
        }

        div {
            display: inline-block;
            vertical-align: middle;
            max-width: 400px;
            text-align: left;
        }

        @media(max-width: 1000px) {
            img {
                display: block;
                margin: 0 auto 30px;
            }

            div {
                display: block;
                margin: 0 auto;
                max-width: 350px;
            }
        }
    }

    .layers {
        overflow: auto;
        padding-top: 60px;
        margin-top: 20px;
        max-width: 1100px;

        img {
            max-width: 600px;

            &:last-of-type {
                float: right;
                margin-top: -200px;
            }
        }

        div {
            padding-top: 80px;
            padding-left: 40px;
            max-width: 400px;
        }

        @media(max-width: 1150px) {
            img {
                margin: 0 auto;

                &:last-of-type {
                    float: none;
                    margin-top: 20px;
                }
            }

            div {
                margin: 0 auto;
                padding-left: 0;
                padding-top: 40px;
            }
        }

        @media(max-width: 1000px) {
            padding-top: 0;
            margin-top: 0;
        }
    }

    .text {
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
        margin-bottom: 0;

        h4 {
            color: black;
            margin-bottom: 1000px;
        }
    }

    .credits {
        background: black;
        padding: 40px 2.5%;
        text-align: center;
        margin-bottom: 0;
        color: white;
        width: 100%;
    }

    .podcast {
        color: black;
        padding: 40px 2.5%;
        background-color: #eadfc4;
        text-align: center;
        width: 100%;
        margin-bottom: 0;

        span {
            font-size: 12px;
        }

        div {
            max-width: 600px;
            width: 95%;
            margin: 0 auto;
        }
    }


    .footer {
        padding: 60px 2.5%;
        width: 100%;
        text-align: center;

        p {
            display: block;
            font-size: 16px;
        }

        a {
            display: block;
            color: #daa520;
            font-size: 16px;
            text-decoration: none;
        }
    }
}