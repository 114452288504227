body {
    background-color: black;

    .april-fools {
        color: white;
        font-size: 24px;
        text-align: center;
        margin-top: 100px;
    }
}
