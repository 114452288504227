footer {
    width: 100%;
    background-color: black;
    text-align: center;
    padding: 20px 0;

    span {
        font-size: 12px;
        text-transform: uppercase;
        color: white;
        letter-spacing: 1px;
    }
}
